import {
  REHAB_FORM_NAMES,
  REHAB_FORM_SUB_TYPES,
  REHAB_FORM_TYPES,
} from "core/consts";
import {
  Auction,
  GetOntologyType,
  RehabFormName,
  RehabFormSubType,
  RehabFormType,
  ValueOf,
} from "core/types";
import { PDFForm } from "pdf-lib";
import { ConversionJobs } from "react-forms-state";
import Translations from "translations/types";
import { GeneralFormPensionPresenter } from "./GeneralForm/Pension/FormSections";
import { convertGeneralFormPensionData } from "./GeneralForm/Pension/conversion";
import { importAuctionToGeneralPensionForm } from "./GeneralForm/Pension/importAuction";
import { generalFormPensionDefinition } from "./GeneralForm/Pension/modelDefinition";
import { generalPensionFormMapping } from "./GeneralForm/Pension/pdfMapping";
import { GeneralFormUniversalPresenter } from "./GeneralForm/Universal/FormSections";
import { convertGeneralFormUniversalData } from "./GeneralForm/Universal/conversion";
import { importAuctionToGeneralUniversalForm } from "./GeneralForm/Universal/importAuction";
import { generalFormUniversalDefinition } from "./GeneralForm/Universal/modelDefinition";
import { generalUniversalFormMapping } from "./GeneralForm/Universal/pdfMapping";
import { MedicalFormPensionPresenter } from "./MedicalForm/Pension/FormSections";
import { convertMedicalFormPensionData } from "./MedicalForm/Pension/conversion";
import { importAuctionToMedicalPensionForm } from "./MedicalForm/Pension/importAuction";
import { medicalFormPensionDefinition } from "./MedicalForm/Pension/modelDefinition";
import { medicalPensionFormMapping } from "./MedicalForm/Pension/pdfMapping";
import { MedicalFormUniversalPresenter } from "./MedicalForm/Universal/FormSections";
import { convertMedicalFormUniversalData } from "./MedicalForm/Universal/conversion";
import { importAuctionToMedicalUniversalForm } from "./MedicalForm/Universal/importAuction";
import { medicalFormUniversalDefinition } from "./MedicalForm/Universal/modelDefinition";
import { medicalUniversalFormMapping } from "./MedicalForm/Universal/pdfMapping";
import { OldRehabFormDataMapped, RehabFormDataMapped } from "./utils";

export const GKV_GENERAL_FORM_PATH =
  "rehab_general_universal_third_draft.pdf" as const;
export const GKV_MEDICAL_FORM_PATH =
  "rehab_medical_universal_fifth_draft.pdf" as const;
export const DRV_GENERAL_FORM_PATH = "general_pension_form_v3.pdf" as const;
export const DRV_MEDICAL_FORM_PATH =
  "medical_pension_form_first_draft.pdf" as const;

export const GKV_GENERAL_FILE_NAME =
  "antrag_anschlussrehabilitation.pdf" as const;
export const GKV_MEDICAL_FILE_NAME =
  "antrag_aerztlicher_befundbericht.pdf" as const;
export const DRV_GENERAL_FORM_NAME = "drv_antrag_G0250.pdf" as const;
export const DRV_MEDICAL_FORM_NAME = "drv_G0260_G0600.pdf" as const;

const REHAB_FORM_PATHS = [
  GKV_GENERAL_FORM_PATH,
  GKV_MEDICAL_FORM_PATH,
  DRV_GENERAL_FORM_PATH,
  DRV_MEDICAL_FORM_PATH,
] as const;

export type RehabFormPath = (typeof REHAB_FORM_PATHS)[number];

const REHAB_FORM_FILE_NAMES = [
  GKV_GENERAL_FILE_NAME,
  GKV_MEDICAL_FILE_NAME,
  DRV_GENERAL_FORM_NAME,
  DRV_MEDICAL_FORM_NAME,
] as const;

export type RehabFormFileName = (typeof REHAB_FORM_FILE_NAMES)[number];

export type RehabFormFormGetters<T extends RehabFormName> = Partial<
  Record<
    keyof RehabFormDataMapped<T>,
    (auction: Auction) => ValueOf<RehabFormDataMapped<T>> | null
  >
>;

export type PdfFillerProps<T extends RehabFormName> = {
  form: PDFForm;
  formData: RehabFormDataMapped<T>;
  locale: Locale;
};

export type PdfFiller<T extends RehabFormName> = ({
  form,
  formData,
  locale,
}: PdfFillerProps<T>) => void;

type ConversionOldToNew = ({
  formDataOld,
}: {
  formDataOld: OldRehabFormDataMapped<any>;
}) => RehabFormDataMapped<any>;

type FormConfig = {
  Form: ({
    onChange,
  }: {
    onChange: (value: any, statePath: string, validation?: any) => void;
  }) => JSX.Element;
  getFormDescription: (translations: Translations) => string;
  getFormTitle: (translations: Translations) => string;
  importAuctionToFormGetters: (
    getOntology: GetOntologyType,
    translations: Translations,
  ) => RehabFormFormGetters<any>;
  modelDefinition: ConversionJobs;
};

type PdfConfig = {
  blankPdfAssetPath: RehabFormPath;
  downloadPdfFileName: RehabFormFileName;
  pdfFiller: PdfFiller<any>;
};

type RehabFormConfig = {
  convertOldFormDataToNew: ConversionOldToNew;
  form: FormConfig;
  formSubType: RehabFormSubType;
  formType: RehabFormType;
  pdf: PdfConfig;
};

export const REHAB_FORM_CONFIGS: Record<RehabFormName, RehabFormConfig> = {
  [REHAB_FORM_NAMES.GKV_MEDICAL]: {
    formType: REHAB_FORM_TYPES.MEDICAL,
    formSubType: REHAB_FORM_SUB_TYPES.UNIVERSAL,
    pdf: {
      blankPdfAssetPath: GKV_MEDICAL_FORM_PATH,
      downloadPdfFileName: GKV_MEDICAL_FILE_NAME,
      pdfFiller: medicalUniversalFormMapping,
    },
    form: {
      Form: MedicalFormUniversalPresenter,
      getFormTitle: (translations: Translations) =>
        translations.patientForms.medicalForm.title,
      getFormDescription: (translations: Translations) =>
        translations.patientForms.medicalForm.description,
      modelDefinition: medicalFormUniversalDefinition,
      importAuctionToFormGetters: importAuctionToMedicalUniversalForm,
    },
    convertOldFormDataToNew: convertMedicalFormUniversalData,
  },
  [REHAB_FORM_NAMES.GKV_GENERAL]: {
    formType: REHAB_FORM_TYPES.GENERAL,
    formSubType: REHAB_FORM_SUB_TYPES.UNIVERSAL,
    pdf: {
      blankPdfAssetPath: GKV_GENERAL_FORM_PATH,
      downloadPdfFileName: GKV_GENERAL_FILE_NAME,
      pdfFiller: generalUniversalFormMapping,
    },
    form: {
      Form: GeneralFormUniversalPresenter,
      getFormTitle: (translations: Translations) =>
        translations.patientForms.generalForm.title,
      getFormDescription: (translations: Translations) =>
        translations.patientForms.generalForm.description,
      modelDefinition: generalFormUniversalDefinition,
      importAuctionToFormGetters: importAuctionToGeneralUniversalForm,
    },
    convertOldFormDataToNew: convertGeneralFormUniversalData,
  },
  [REHAB_FORM_NAMES.DRV_GENERAL]: {
    formType: REHAB_FORM_TYPES.GENERAL,
    formSubType: REHAB_FORM_SUB_TYPES.PENSION,
    pdf: {
      blankPdfAssetPath: DRV_GENERAL_FORM_PATH,
      downloadPdfFileName: DRV_GENERAL_FORM_NAME,
      pdfFiller: generalPensionFormMapping,
    },
    form: {
      Form: GeneralFormPensionPresenter,
      getFormTitle: (translations: Translations) =>
        translations.patientForms.drvGeneralForm.title,
      getFormDescription: (translations: Translations) =>
        translations.patientForms.drvGeneralForm.description,
      modelDefinition: generalFormPensionDefinition,
      importAuctionToFormGetters: importAuctionToGeneralPensionForm,
    },
    convertOldFormDataToNew: convertGeneralFormPensionData,
  },
  [REHAB_FORM_NAMES.DRV_MEDICAL]: {
    formType: REHAB_FORM_TYPES.MEDICAL,
    formSubType: REHAB_FORM_SUB_TYPES.PENSION,
    pdf: {
      blankPdfAssetPath: DRV_MEDICAL_FORM_PATH,
      downloadPdfFileName: DRV_MEDICAL_FORM_NAME,
      pdfFiller: medicalPensionFormMapping,
    },
    form: {
      Form: MedicalFormPensionPresenter,
      getFormTitle: (translations: Translations) =>
        translations.patientForms.drvMedicalForm.title,
      getFormDescription: (translations: Translations) =>
        translations.patientForms.drvMedicalForm.description,
      modelDefinition: medicalFormPensionDefinition,
      importAuctionToFormGetters: importAuctionToMedicalPensionForm,
    },
    convertOldFormDataToNew: convertMedicalFormPensionData,
  },
};

export const getRehabFormConfig = (formName: RehabFormName): RehabFormConfig =>
  REHAB_FORM_CONFIGS[formName];
